import React, { useEffect, useState } from 'react'
import CryptoJs from 'crypto-js'
import { Link } from 'gatsby'
import { errorMessage } from '../utils/responsemessage.js'
import axios from 'axios'
import { connect } from 'react-redux'
import { lognRequest } from '../redux'
import LinearProgress from '@material-ui/core/LinearProgress';
import { MDBContainer, MDBBtn, MDBIcon, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import { AXIOSLINK } from '../utils/constants.js'
import '../components/button.css'


const Login = ({ loginData, lognRequest, loginModal,data }) => {

  const [error, setError] = useState(false);


  const [loginValue, setLoginValue] = useState(loginData);

  const [modal, setModal] = useState(loginModal);



  useEffect(() => {
    // debugger
    setError(false)
    //console.log('@@@@@@@@@===>', loginData)
    setLoginValue(loginData);
    invokeLogin();
    //toggle();
  }, [loginData, loginModal])

  function invokeLogin() {
    // debugger

    const response = loginData.data;

    if (response.status == 200) {
      localStorage.setItem('token', response.data.jwt)
      localStorage.isAuthenticated = true
       window.location.pathname = '/home'
      toggle(!modal);
    } else if (loginData.error) {
      //console.log('====LoginError===');
      setError(true)
      toggle(true);
    } else {
      toggle(!modal);
    }
  }


  function toggle(flag) {
    setModal(flag);
    //setError(false)
    loginData.error = false;

    //console.log('Modal==>', modal);
    //console.log('Error==>', error);

  }


  const initialFormData = Object.freeze({
    username: '',
    password: '',
  })
  const [formData, updateFormData] = React.useState(initialFormData)
  const handleChange = e => {
    updateFormData({
      ...formData,

      // Trimming any whitespace
      [e.target.name]: e.target.value.trim(),
    })
  }
  const sendData = e => {
    var x = document.getElementById("message_1");
    x.innerHTML = "";
    e.preventDefault()
    //console.log({ formData })
    // debugger
    const pwd = CryptoJs.MD5(formData.password).toString()
    const data = {
      identifier: formData.email,
      password: pwd,
     // password: formData.password,
      
    }
    //console.log(data.password);
         
          axios.get(AXIOSLINK + 'users',{
          params: {
           email:data.identifier,
         }
       })
       .then(function (result) {
         console.log(result)
         var ExpiryDate = result.data[0].NextRenewalDate;


         var TodaysDate = new Date().toUTCString();
         var NewTodaysDate = new Date(TodaysDate);

         var Expiry = new Date(ExpiryDate).toUTCString(); 
         var NewExpiryDate = new Date(Expiry);
         
        if(NewTodaysDate < NewExpiryDate)
        {
          if(result.data[0].role.id == 1 && result.data[0].confirmed == true){
             lognRequest(data);
             console.log("========>", loginData);
          }
          else{
           var x = document.getElementById("message_1");
            x.innerHTML = "You are not authenticated";
            x.style.color = "red";
         }
        }
        else{
          var x = document.getElementById("message_1");
           x.innerHTML = "Your account date is expired";
           x.style.color = "red";
        }
       })
    
  }

  return (

    <>

      <MDBContainer style={{borderRadius: '50px'}}>
        <MDBModal isOpen={modal} toggle={toggle}  >
          <MDBModalBody style={{backgroundColor:  "rgb(249, 249, 255)"}}  >
            <div >
              {loginValue.loading && <LinearProgress style={{ 'marginTop': '-30px', 'marginLeft': '-20px', 'marginRight': '-20px' }} />
              }
              <form >
                <h2 className="title"> Login Page</h2>

                <p className="or">
                </p>
                <div className="email-login">
                  <label htmlFor="email">
                    {' '}
                    <b>Email Address</b>
                  </label>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    name="email"
                    required
                    onChange={handleChange}
                    style={{padding: '15px 20px',marginTop: '8px',border: '1px solid #ccc',borderRadius: '8px',boxSizing: 'border-box'}}
            
                  />
                  <label htmlFor="psw">
                    <b>Password</b>
                  </label>
                  <input
                    type="password"
                    placeholder="Enter Password"
                    name="password"
                    required
                    onChange={handleChange}
                  />
                </div>
      
                <div className="text-center mt-4">
                <button type="submit" class="btn btn-success loginbtn" onClick={sendData}
                style={{backgroundColor:"rgb(0, 128, 0)",
                borderRadius:"7px",
                fontSize:"16px",
                width:"27%"}}>Login</button>
                </div>

                <p  id="message_1"></p>
                
                <a className="forget-pass" href="/forgotpassword" >
                  Forgot password?
                </a>
                {/* <a className="sign-up"  href="/signup" style={{paddingLeft:'45%'}} >
                  Sign up
                </a> */}
                
              </form>


              {error && <label htmlFor="error" style={{ 'color': (error ? 'red' : 'green'), 'textAlign': 'center' }}>
                <br />
                <p>  {loginValue.msg ? loginValue.msg : ''}</p>
              </label>
              }
            </div>

          </MDBModalBody>

        </MDBModal>
      </MDBContainer>


    </>
  )
}

const mapStateToProps = state => {
  return {
    loginData: state.loginData
  }
}

const mapDispatchToProps = dispatch => {

  return {
    lognRequest: (data) => dispatch(lognRequest(data))
  }

}



export default connect(mapStateToProps, mapDispatchToProps)(Login)

//export default Login

