import { LOGIN_ERROR, LOGIN_SUCCESS, LOGIN_REQUEST } from './loginActionTypes'
import axios from 'axios'
import { AXIOSLINK } from '../../utils/constants'

export const loginRequest = () => {
  return {
    type: LOGIN_REQUEST,
  }
}

export const loginSuccess = user => {
  return {
    type: LOGIN_SUCCESS,
    payload: user,
  }
}

export const loginError = (msg, errorFlag) => {
  return {
    type: LOGIN_ERROR,
    payload: msg,
    error: errorFlag,
  }
}

export const lognRequest = loginData => {
  return dispatch => {
    dispatch(loginRequest)

    axios({
      method: 'post',
      url: AXIOSLINK + 'auth/local',
      data: loginData,
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then(response => {
        console.log('Success Response: ', response)
        console.log('Success Response: ', response.data)
        console.log('Success Response: ', response.data.jwt)
        const user = response.data
        //dispatch(saveEmployeeSuccess(employee));
        dispatch(loginSuccess(response))
      })
      .catch(error => {
        console.log(error)
        debugger
        if (error.response)
          dispatch(
            loginError(error.response.data.message[0].messages[0].message, true)
          )
        else dispatch(loginError('Unknown error', true))
        console.log('====================================')
        console.log('error creating Page:', error)
        console.log('====================================')

        //console.log(error.response.data.message[0].messages[0].message)
      })
  }
}
